<template>
  <!-- 代理商新增，修改 -->
  <div>
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%">
      <el-form label-width="120px" ref="form">
        <el-form-item label="礼品名称" required>
          <el-input v-model="form.gift_name" placeholder="礼品名称"></el-input>
        </el-form-item>
        <el-form-item label="活动" required>
          <el-select v-model="form.activity_id" placeholder="请选择">
            <el-option
              v-for="item in activityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="礼品类型" required>
          <el-radio v-model="form.type" label="course">课程</el-radio>
          <el-radio v-model="form.type" label="coupon">优惠券</el-radio>
        </el-form-item>
        <el-form-item label="课程" required>
          {{ curCourse.title || "请选择课程" }}
        </el-form-item>

        <div v-if="form.type == 'course'">
          <div class="flex">
            <el-input v-model="key" placeholder="课程名称"></el-input>
            <el-button @click="getCourseList(1)">搜索</el-button>
          </div>
          <auto-table
            :DataList="DataList"
            :option="Option"
            :total="Total"
            @changePage="getCourseList"
          >
            <template #activity_time="{ row }">
              <div>
                <p>开始:{{ row.start_time }}</p>
                <p>结束:{{ row.end_time }}</p>
              </div>
            </template>
            <template #handler="{ row }">
              <div>
                <el-button
                  type="primary"
                  size="small"
                  @click="changeCourse(row)"
                  >选择</el-button
                >
              </div>
            </template>
          </auto-table>
        </div>

        <el-form-item label="封面">
          <avatar-uploader @getImgUrl="(v) => (form.pic = v)" :url="temp.pic">
          </avatar-uploader>
        </el-form-item>
        <el-form-item label="礼品价格" required>
          <el-input v-model="form.amount" placeholder="礼品价格"></el-input>
        </el-form-item>
        <el-form-item label="过期时间" required>
          <el-date-picker
            v-model="form.end_time"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="form.id"
          type="primary"
          @click="updateForm"
          :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary" :loading="loading" @click="addForm"
          >新 增</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import citySelect from "@/components/select/citySelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
import cooperatorSelect from "../../../components/select/cooperatorSelect.vue";
export default {
  components: {
    citySelect,
    cooperatorSelect,
  },
  data() {
    return {
      clear: false,
      form: {},
      temp: {},
      dialogVisible: false,
      loading: false,

      activityList: [],
      DataList: [],
      Option: [
        { name: "名称", value: "title" },
        { name: "价格", value: "discount_amout" },
        { name: "课节数", value: "course_num" },
        { name: "类目", value: "categories_child" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      key: "",
      curCourse: {},
    };
  },

  mounted() {
    this.getActivityList();
  },

  methods: {
    changeCourse(row) {
      this.curCourse = row;
      this.form.pic = row.face_url;
      this.temp.pic = row.face_url;
      this.form.amount = row.discount_amout;
      this.DataList = [];
    },
    //获取列表
    getCourseList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/masterSetPrice/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: 2,
          type: "mechanism_offline",
          categories: "体育运动",
          title: this.key,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    //活动
    getActivityList() {
      this.$axios({
        url: "/user/businessActivityType/queryListPage",
        params: {
          currentPage: 1,
          pageSize: 20,
          status: 2,
        },
      }).then((res) => {
        this.activityList = res.data.data;
      });
    },
    addForm() {
      if (!this.form.type) {
        this.$message("请选择类型");
        return;
      }
      if (!this.form.end_time) {
        this.$message("请选择过期时间");
        return;
      }
      if (this.form.type == "course" && !this.curCourse.id) {
        this.$message("请选择课程");
        return;
      }

      if (this.form.type == "course") {
        this.form.course_id = this.curCourse.id || null;
      }
      this.loading = true;
      this.$axios({
        url: "/user/userGift/insert",
        method: "post",
        data: this.form,
      })
        .then((res) => {
          this.$notify({
            message: "新增成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
          this.loading = false;
          this.$emit("success", 1);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updateForm() {
      if (this.form.type == "course") {
        this.form.course_id = this.curCourse.id || null;
      }

      const data = ObjectChangedValue(this.temp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      this.loading = true;
      this.$axios({
        url: "/user/userGift/update",
        method: "post",
        data: {
          id: this.form.id,
          ...data,
        },
      })
        .then((res) => {
          this.$notify({
            message: "更新成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
          this.loading = false;
          this.$emit("success", 1);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
      } else {
        this.form = {};
        this.temp = {};
      }
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
</style>